import {useEffect, useState} from 'react';
import {PageTitle} from '../../../_metronic/layout/core';
import {AuctionCard, TodaysReservation} from '../../components/Dashboard';
import {CustomModal} from '../../components/Modal';
import {useNavigate} from 'react-router-dom';
import {useThemeMode} from '../../../_metronic/partials';
import {useAuth} from '../../modules/auth';
import {toast} from 'react-toastify';
import {API_ERROR_MESSAGE, TOAST_GENERAL} from '../../Helpers';
import {DashboardData} from '../Admin';
import TableLoader from '../../DataTable/TableLoader';

const initialState = {
  data: [],
  isModalOpen: false,
  selectedRow: null,
};
interface InitialState {
  data: any[];
  isModalOpen: boolean;
  selectedRow: any | null;
}

export const DashboardForRestaurant = () => {
  const [state, setState] = useState<InitialState>(initialState);
  const [isLoading, setIsLoading] = useState(true);
  const {currentUser, dashboardModule, setCurrentUser} = useAuth();
  const {mode} = useThemeMode();
  const navigate = useNavigate();
  const [dashboardData, setDashboardData] = useState<DashboardData | null>(null);
  const [statusCode, setStatusCode]: any = useState('');
  const [message, setMessage] = useState('');
  const [authKey, setAuthKey] = useState('');
  const [isStripeConnect, setIsStripeConnect] = useState(0);
  const [stripeModalView, setStripeModalView] = useState(false);
  const getDashboardData = async () => {
    const result = await dashboardModule?.getAllDashboardData();

    if (result?.success) {
      setDashboardData(result?.data);
    } else {
      if (result?.status !== 600) {
        toast.error(result?.message || API_ERROR_MESSAGE, {
          toastId: TOAST_GENERAL,
        });
      }
    }
    setIsLoading(false);
  };
  useEffect(() => {
    getDashboardData();
  }, []);

  console.log('stripe connect', isStripeConnect, authKey, message, stripeModalView);

  useEffect(() => {
    const getQueryParams = () => {
      const params = new URLSearchParams(window.location.search);
      const filteredParams = {};
      params.forEach((value, key) => {
        if (key === 'message') {
          setMessage(value);
        } else if (key === 'authKey') {
          setAuthKey(value);
        } else if (key === 'isStripeConnected') {
          setIsStripeConnect(Number(value));
        } else {
          setStatusCode(value);
        }
      });
      return filteredParams;
    };
    getQueryParams();
  }, []);

  useEffect(() => {
    if (message) {
      setStripeModalView(true);
      if (statusCode === '200') {
        toast.success(message);
      } else {
        toast.error('Stripe Requirements are missing !!');
      }

      if (Number(isStripeConnect) === 1) {
        setCurrentUser((currentUser) => {
          const updatedUser = {
            ...currentUser,
            isStripeConnect: isStripeConnect,
          };
          localStorage.setItem('current-user', JSON.stringify(updatedUser));
          return updatedUser;
        });
      }

      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }, [message, statusCode, authKey, isStripeConnect]);

  return (
    <>
      <PageTitle breadcrumbs={[]}>Dashboard</PageTitle>
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-5 col-md-5'>
          <TodaysReservation
            className='card-xl-stretch mb-xl-8 dashboard-list '
            dashboardData={dashboardData?.topReservations}
          />
        </div>
        <div className='col-xl-7 col-md-7'>
          <div className={`card card-xl-stretch mb-xl-8 dashboard-list relative`}>
            <div
              className={`card-header border-0 pt-5 sticky ${
                mode === 'dark' ? 'bg-light' : 'bg-white'
              }`}
            >
              <h3 className='card-title align-items-start'>
                <span className='card-label fw-bold text-dark'>Ongoing Auctions</span>
              </h3>
            </div>
            <div className='card-body pt-5'>
              {isLoading ? (
                <TableLoader />
              ) : Array.isArray(dashboardData?.TopAuctions) && dashboardData?.TopAuctions.length ? (
                dashboardData?.TopAuctions?.map((ele, i) => (
                  <div className='col-xl-12 mb-md-4 mt-4 mt-md-0 mb-4' key={i}>
                    <AuctionCard
                      handleOnClick={() =>
                        setState({...state, isModalOpen: true, selectedRow: ele})
                      }
                      className='card-xl-stretch mb-xl-8 cursor-pointer'
                      title='Carbon'
                      description='Lands, Houses, Ranchos, Farms'
                      data={ele}
                    />
                  </div>
                ))
              ) : (
                <>
                  <p className='fs-2 fw-bold text-gray-600 m-0 text-center'>
                    No ongoing auction found{' '}
                  </p>
                </>
              )}
              {Array.isArray(dashboardData?.TopAuctions) &&
                (dashboardData?.TopAuctions?.length ?? 0) > 10 && (
                  <div className='text-center pt-2 mt-6'>
                    <span
                      className='text-primary cursor-pointer view-more-btn'
                      onClick={() => {
                        navigate('/auction');
                      }}
                    >
                      View More
                    </span>
                  </div>
                )}
            </div>
          </div>
        </div>{' '}
      </div>
      {state.isModalOpen && (
        <CustomModal
          title='Auction Details'
          data={state.selectedRow}
          handleCancelCallback={() => setState({...state, isModalOpen: false, selectedRow: null})}
          handleSaveCallback={(value) => {
            setState({...state, isModalOpen: false, selectedRow: null});
          }}
          type='AUCTION_DETAILS'
        />
      )}

      {stripeModalView && (
        <CustomModal
          title=''
          data=''
          handleCancelCallback={() => {
            setStripeModalView(false);
            // window.location.reload();
            setMessage('');
            setStatusCode('');
          }}
          type='STRIPE_ONBOARD'
          stripeTitle={message}
          handleSaveCallback={() => {
            setStripeModalView(false);
            // window.location.reload();
            setMessage('');
            setStatusCode('');
          }}
        />
      )}
    </>
  );
};
