import {Navigate, Route, Routes} from 'react-router-dom';
import {AuthLayout} from '../modules/auth/AuthLayout';
import {VerifyRestaurantOtpVerification} from '../modules/auth/components/VerifyRestaurantOtpVerification';
import {SendRestaurantOtpVerification} from '../modules/auth/components/SendRestaurantOtpVerification';

const RestaurantOtpVerificationRoutes = () => {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        {/* Redirect to Dashboard after success login/registration */}
        <Route path='auth/*' element={<Navigate to='/send-otp' />} />

        {/* Pages */}
        <Route path='send-otp' element={<SendRestaurantOtpVerification />} />
        <Route path='otp-verification' element={<VerifyRestaurantOtpVerification />} />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  );
};

export {RestaurantOtpVerificationRoutes};
