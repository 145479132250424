/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers';
import {useThemeMode} from '../../../_metronic/partials';
import TableLoader from '../../DataTable/TableLoader';
import '../../DataTable/dataTable.css';
import {convertUtcToLocal} from '../../Helpers';
import {TopAuction} from '../../pages/Admin';
import {CalculateTimeDifference} from '../common/CalculateTimeDifference';

type Props = {
  className: string;
  title: string;
  subTitle?: string;
  isBiddingTable?: boolean;
  onEyeIconClick: (row) => void;
  dashboardData: TopAuction | null | undefined;
};

const AuctionDashboard: React.FC<Props> = ({
  className,
  title,
  subTitle = '',
  isBiddingTable = false,
  onEyeIconClick,
  dashboardData,
}) => {
  const {mode} = useThemeMode();
  const navigate = useNavigate();

  return (
    <div className={`card ${className} relative`}>
      {/* begin::Header */}
      <div
        className={`card-header border-0 pt-5 sticky ${mode === 'dark' ? 'bg-light' : 'bg-white'}`}
      >
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{title}</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>{subTitle}</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        <div className='tab-content'>
          <div className='tab-pane fade show active' id='kt_table_widget_5_tab_1'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}

              <table className=' table  align-middle gs-0 gy-2'>
                {/* begin::Table head */}
                <thead>
                  <tr className='border-0'>
                    <th className='p-0 min-w-50px'></th>
                    <th className='p-0 min-w-50px'></th>
                    <th className='p-0 min-w-50px'></th>
                    <th className='p-0 min-w-5opx'></th>
                    <th className='p-0 min-w-50px'></th>
                    <th className='p-0 min-w-50px'></th>
                    <th className='p-0 min-w-50px'></th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {Array.isArray(dashboardData) ? (
                    dashboardData?.length ? (
                      dashboardData?.map((e, i) => (
                        <>
                          <tr key={i}>
                            {!isBiddingTable && (
                              <>
                                <td>
                                  <div className='symbol me-2'>
                                    <span className='symbol-label'>
                                      <img
                                        src={
                                          e?.image
                                            ? e.image
                                            : toAbsoluteUrl('/media/avatars/restaurant-blank.png')
                                        }
                                        className=' rounded-circle  symbol-label align-self-end w-[40px] h-[40px]'
                                        alt=''
                                      />
                                    </span>
                                  </div>
                                </td>
                                <td style={{minWidth: 132}}>
                                  <Link
                                    to={`/restaurants/${e?.restaurantId}`}
                                    className='text-dark text-capitalize fw-bold text-hover-primary mb-1 fs-6'
                                  >
                                    {e?.restaurantName && e?.restaurantName.length > 25
                                      ? e?.restaurantName.slice(0, 25) + '...'
                                      : e?.restaurantName}
                                  </Link>
                                  <span className='text-muted fw-semibold d-flex  align-items-center'>
                                    <KTIcon iconName='geolocation' className='fs-5' />
                                    <span className='mx-2'>{e?.restaurantCity}</span>
                                  </span>
                                </td>
                              </>
                            )}
                            <td
                              className='text-end text-muted fw-semibold '
                              style={{minWidth: 220}}
                            >
                              <div className='d-flex align-items-center'>
                                <KTIcon iconName='time' className='fs-3' />
                                <span className='mx-2 text-uppercase'>
                                  {' '}
                                  {convertUtcToLocal(e?.dineDate)}
                                </span>
                              </div>
                            </td>
                            <td className='text-end text-muted fw-semibold '>
                              <div className='d-flex align-items-center'>
                                <KTIcon iconName='user' className='fs-3' />
                                <span className='mx-2'>{e?.capacity}</span>
                              </div>
                            </td>
                            <td className='text-end text-muted fw-semibold '>
                              <div className='d-flex align-items-center'>
                                <span className='mx-2 text-capitalize'> {e?.auctionStatus}</span>
                              </div>
                            </td>
                            <td className='text-end text-muted fw-semibold' style={{minWidth: 200}}>
                              <div className='d-flex align-items-center'>
                                <KTIcon iconName='watch' className='fs-6' />
                                <span className='mx-2'>
                                  {e?.auctionStatus === 'ongoing' ? (
                                    <CalculateTimeDifference time={e?.deadline} />
                                  ) : (
                                    '-'
                                  )}
                                </span>
                              </div>
                            </td>
                            <td className='text-center'>
                              <span className='badge badge-light-primary py-2 px-4 fs-6'>
                                {e?.highestBid ? '$' + e?.highestBid : 'No bids'}
                              </span>
                            </td>
                            <td className='text-end'>
                              <span
                                onClick={() => {
                                  onEyeIconClick(e);
                                }}
                                className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                              >
                                <KTIcon iconName='eye' className='fs-2' />
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={8}>
                              <div className='border border-bottom-2 border-blue-300' />
                            </td>
                          </tr>
                        </>
                      ))
                    ) : (
                      <>
                        <p className='fs-2 fw-bold text-gray-600 m-0 text-center'>
                          No Recent Auctions found
                        </p>
                      </>
                    )
                  ) : (
                    <TableLoader />
                  )}
                </tbody>
                {/* end::Table body */}
              </table>
            </div>
            {/* end::Table */}
          </div>
          {Array.isArray(dashboardData) && dashboardData?.length >= 10 && (
            <div className='text-center mt-5 mb-5'>
              <span
                className='cursor-pointer view-more-btn'
                onClick={() => {
                  navigate(`${isBiddingTable ? '/bidding' : '/auction'}`);
                }}
              >
                View More
              </span>
            </div>
          )}
        </div>
      </div>
      {/* end::Body */}
    </div>
  );
};

export {AuctionDashboard};
