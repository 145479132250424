/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react';
import {ToastContainer, toast} from 'react-toastify';
import {useNavigate} from 'react-router-dom';
import {PhoneInput, defaultCountries, parseCountry} from 'react-international-phone';
import 'react-international-phone/style.css';
import {PhoneNumberUtil} from 'google-libphonenumber';
import {useAuth} from '../core/Auth';
import * as Yup from 'yup';
import {useSessionReducer} from '../../../hook/useSessionReducer';
import reducerFunc from '../../../pages/Admin/Transactions/actions';
import {CustomModal} from '../../../components/Modal';

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone: string) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

export function SendRestaurantOtpVerification() {
  const {currentUser} = useAuth();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const {restaurantModule} = useAuth();
  const [mobileNumber, setMobileNumber]: any = useState<any>();
  const [isChecked, setIsChecked] = useState(false);
  const [timestamp, setTimestamp]: any = useState(null);
  const [state, dispatch] = useSessionReducer<any, any, string>(reducerFunc, {}, 'USER');

  const handleCheckboxChange = (e) => {
    const checked = e.target.checked;
    setIsChecked(checked);

    if (checked) {
      const currentTimestamp: any = new Date().toISOString();
      setTimestamp(currentTimestamp);
    } else {
      setTimestamp(null);
    }
  };

  console.log('timestamp', timestamp);

  const [countries] = useState(
    defaultCountries.filter((country) => {
      const {iso2} = parseCountry(country);
      return [
        'us',
        'am',
        'au',
        'ua',
        'gb',
        'in',
        'fr',
        'tr',
        'by',
        'ca',
        'ie',
        'is',
        'ht',
        'fi',
        'ar',
        'uk',
      ].includes(iso2);
    })
  );
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setMessage('');

    try {
      if (!isPhoneValid(mobileNumber)) {
        setMessage('Please enter a valid phone number.');
        setLoading(false);
        return;
      }

      const formattedMobileNumber = mobileNumber.replace(/^\+/, '');

      const response: any = await restaurantModule?.restaurantSendOtp(
        String(formattedMobileNumber),
        timestamp,
        currentUser?.id
      );
      if (response?.success) {
        const {hash} = response.data;
        localStorage.setItem('mobileNumber', formattedMobileNumber);
        localStorage.setItem('hash', hash);
        toast.success(response?.message);
        navigate('/otp-verification');
      } else {
        toast.error('Something went wrong!');
      }
    } catch (error: any) {
      toast.error(error.response?.data?.message || error?.message || 'Something went wrong!');
    }

    setLoading(false);
  };

  return (
    <form className='form w-100' onSubmit={handleSubmit} noValidate id='kt_send_otp_form'>
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Send OTP</h1>
      </div>

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='fw-bold fs-6 mb-2'>Phone Number</label>
        <PhoneInput
          defaultCountry='us'
          value={mobileNumber}
          onChange={(phone) => setMobileNumber(phone)}
          countries={countries}
          inputClassName={'form-control form-control-solid mb-3 mb-lg-0'}
          inputProps={{name: 'phoneNumber', autoComplete: 'off', disabled: loading}}
        />
        {message && (
          <div className='fv-plugins-message-container'>
            <span role='alert' style={{color: 'red'}}>
              {message}
            </span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      <div className='fv-row d-flex flex-column align-items-center justify-content-center'>
        <label className='form-label fw-bold'>
          <input
            type='checkbox'
            checked={isChecked}
            onChange={handleCheckboxChange}
            className='form-check-input me-2'
          />
          By Checking This Box, You Are Agreeing To Our Terms Of Use And Restaurant Terms.{' '}
          <span
            className='btn btn-link text-decoration-underline'
            onClick={(event: any) => {
              event.preventDefault();
              dispatch({type: 'OPEN_MODAL', payload: 'TERMS'});
            }}
          >
            Terms And Agreement
          </span>
        </label>
      </div>

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_send_otp_submit'
          className='btn btn-primary'
          disabled={!isChecked}
        >
          {!loading && <span className='indicator-label'>Send OTP</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      <ToastContainer />
      {/* end::Action */}

      {state.isModalOpen && (
        <CustomModal
          type='TERMS'
          handleCancelCallback={() => dispatch({type: 'CLOSE_MODAL'})}
          handleSaveCallback={() => dispatch({type: 'CLOSE_MODAL'})}
        />
      )}
    </form>
  );
}
