/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react';
import {ToastContainer, toast} from 'react-toastify';
import OtpInput from 'react-otp-input';
import {useNavigate} from 'react-router-dom';
import {useAuth} from '../core/Auth';

export function VerifyRestaurantOtpVerification() {
  const [loading, setLoading] = useState(false);
  const [message, setMassage] = useState('');
  const {restaurantModule, logout} = useAuth();
  const navigate = useNavigate();
  const [resendTimer, setResendTimer] = useState(60);
  const [resendLoading, setResendLoading] = useState(false);
  const [otp, setOtp] = useState('');
  const storedValue = localStorage.getItem('hash');
  const otpMobileNumber = localStorage.getItem('mobileNumber');
  const mobileNumber = otpMobileNumber ? otpMobileNumber : null;
  const hashKey: any = storedValue ? storedValue : null;

  // Handle resend timer countdown
  useEffect(() => {
    let interval;
    if (resendTimer > 0) {
      interval = setInterval(() => {
        setResendTimer((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [resendTimer]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setMassage('');
    try {
      if (otp.length !== 6) {
        setMassage('Please enter a 6-digit OTP.');
        setLoading(false);
        return;
      }
      if (!/^\d+$/.test(otp)) {
        setMassage('Please enter numeric values only.');
        setLoading(false);
        return;
      }
      const response = await restaurantModule?.verifyOtp(otp, hashKey);
      if (response?.status === 200) {
        logout();
        toast.success(response?.message);
        navigate('/auth');
      } else {
        toast.error('Something went wrong!');
      }
    } catch (error: any) {
      toast.error(error.response.data.message || 'Something went wrong!');
      setLoading(false);
    }
  };

  // Handle OTP resend request
  const handleResendOtp = async () => {
    setResendLoading(true);
    try {
      const response = await restaurantModule?.sendOtp(String(mobileNumber));
      if (response?.status === 200) {
        const {hash} = response.data;
        localStorage.setItem('hash', hash);
        toast.success('OTP resent successfully!');
        setResendTimer(60);
      } else {
        toast.error('Something went wrong!');
      }
    } catch (error: any) {
      toast.error(error.response.data.message || 'Failed to resend OTP!');
    } finally {
      setResendLoading(false);
    }
  };

  return (
    <form className='form w-100' onSubmit={handleSubmit} noValidate id='kt_login_signin_form'>
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Verify 6 Digits Code</h1>
      </div>

      {/* begin::Form group */}
      <div className='fv-row mb-8 d-flex flex-column align-items-center justify-content-center'>
        <OtpInput
          value={otp}
          onChange={(value) => setOtp(value)}
          numInputs={6}
          shouldAutoFocus
          renderSeparator={<span>-</span>}
          inputStyle={{
            border: '1px solid transparent',
            borderRadius: '8px',
            width: '50px',
            height: '50px',
            fontSize: '16px',
            color: '#000',
            fontWeight: '600',
            caretColor: 'blue',
            outline: 'none',
            textAlign: 'center',
          }}
          // Add the renderInput property
          renderInput={(props) => <input {...props} />}
        />
        {message && (
          <div className='fv-plugins-message-container'>
            <span role='alert' style={{color: 'red'}}>
              {message}
            </span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button type='submit' id='kt_sign_in_submit' className='btn btn-primary'>
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* Resend OTP Button */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          onClick={handleResendOtp}
          disabled={resendTimer > 0 || resendLoading}
        >
          {resendLoading
            ? 'Resending...'
            : resendTimer > 0
            ? `Resend OTP in ${resendTimer}s`
            : 'Resend OTP'}
        </button>
      </div>
      <ToastContainer />
      {/* end::Action */}
    </form>
  );
}
