import {useEffect, useState} from 'react';
import {ToastContainer, toast} from 'react-toastify';
import {PhoneInput, defaultCountries, parseCountry} from 'react-international-phone';
import 'react-international-phone/style.css';
import {PhoneNumberUtil} from 'google-libphonenumber';
import * as Yup from 'yup';
import {useAuth} from '../../../modules/auth';
import OTPInput from 'react-otp-input';

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone: string) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

const RestaurantMobileVerificationBody = ({
  handleCancelCallback,
}: {
  handleCancelCallback: () => void;
}) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const {restaurantModule} = useAuth();
  const [mobileNumber, setMobileNumber]: any = useState<any>();
  const [otpLoading, setOtpLoading] = useState(false);
  const [otpMessage, setOtpMessage] = useState('');
  const [resendTimer, setResendTimer] = useState(60);
  const [resendLoading, setResendLoading] = useState(false);
  const [otp, setOtp] = useState('');
  const storedValue = localStorage.getItem('hash');
  const otpMobileNumber = localStorage.getItem('mobileNumber');
  const mobileNumberVal = otpMobileNumber ? otpMobileNumber : null;
  const hashKey: any = storedValue ? storedValue : null;
  const [otpView, setOtpView] = useState(false);

  const [countries] = useState(
    defaultCountries.filter((country) => {
      const {iso2} = parseCountry(country);
      return [
        'us',
        'am',
        'au',
        'ua',
        'gb',
        'in',
        'fr',
        'tr',
        'by',
        'ca',
        'ie',
        'is',
        'ht',
        'fi',
        'ar',
        'uk',
      ].includes(iso2);
    })
  );

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setMessage('');

    try {
      if (!isPhoneValid(mobileNumber)) {
        setMessage('Please enter a valid phone number.');
        setLoading(false);
        return;
      }

      const formattedMobileNumber = mobileNumber.replace(/^\+/, '');

      const response = await restaurantModule?.sendOtp(String(formattedMobileNumber));
      if (response?.success) {
        setOtpView(true);
        const {hash} = response.data;
        localStorage.setItem('mobileNumber', formattedMobileNumber);
        localStorage.setItem('hash', hash);
        toast.success(response?.message);
      } else {
        toast.error('Something went wrong!');
      }
    } catch (error: any) {
      toast.error(error.response?.data?.message || 'Something went wrong!');
    }

    setLoading(false);
  };

  // Handle resend timer countdown
  useEffect(() => {
    let interval;
    if (resendTimer > 0) {
      interval = setInterval(() => {
        setResendTimer((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [resendTimer]);

  // Handle OTP submission
  const handleSubmits = async (event) => {
    event.preventDefault();
    setOtpLoading(true);
    setOtpMessage('');
    try {
      if (otp.length !== 6) {
        setOtpMessage('Please enter a 6-digit OTP.');
        setOtpLoading(false);
        return;
      }
      if (!/^\d+$/.test(otp)) {
        setOtpMessage('Please enter numeric values only.');
        setOtpLoading(false);
        return;
      }
      const response = await restaurantModule?.verifyOtp(otp, hashKey);
      if (response?.status === 200) {
        window.location.reload();
        handleCancelCallback();
        toast.success(response?.message);
      } else {
        toast.error('Something went wrong!');
      }
    } catch (error: any) {
      toast.error(error.response?.data?.message || 'Something went wrong!');
      setOtpLoading(false);
    }
  };

  // Handle OTP resend request
  const handleResendOtp = async () => {
    setResendLoading(true);
    try {
      const response = await restaurantModule?.sendOtp(String(mobileNumberVal));
      if (response?.status === 200) {
        const {hash} = response.data;
        localStorage.setItem('hash', hash);
        toast.success('OTP resent successfully!');
        setResendTimer(60);
      } else {
        toast.error('Something went wrong!');
      }
    } catch (error: any) {
      toast.error(error.response?.data?.message || 'Failed to resend OTP!');
    } finally {
      setResendLoading(false);
    }
  };

  if (otpView) {
    return (
      <form id='kt_modal_add_coupon_form' className='form' onSubmit={handleSubmits} noValidate>
        <div
          className='d-flex flex-column me-n7 pe-7'
          id='kt_modal_add_coupon_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_coupon_header'
          data-kt-scroll-wrappers='#kt_modal_add_coupon_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='text-center mb-11'>
            <h1 className='text-dark fw-bolder mb-3'>Verify 6 Digits Code</h1>
          </div>

          {/* begin::Form group */}
          <div className='fv-row mb-8 d-flex flex-column align-items-center justify-content-center'>
            <OTPInput
              value={otp}
              onChange={(value) => setOtp(value)}
              numInputs={6}
              shouldAutoFocus
              renderSeparator={<span>-</span>}
              inputStyle={{
                border: '1px solid transparent',
                borderRadius: '8px',
                width: '50px',
                height: '50px',
                fontSize: '16px',
                color: '#000',
                fontWeight: '600',
                caretColor: 'blue',
                outline: 'none',
                textAlign: 'center',
              }}
              // Add the renderInput property
              renderInput={(props) => <input {...props} />}
            />
            {otpMessage && (
              <div className='fv-plugins-message-container'>
                <span role='alert' style={{color: 'red'}}>
                  {otpMessage}
                </span>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Action */}
          <div className='d-grid mb-10'>
            <button type='submit' id='kt_sign_in_submit' className='btn btn-primary'>
              {!otpLoading && <span className='indicator-label'>Continue</span>}
              {otpLoading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          {/* Resend OTP Button */}
          <div className='text-center'>
            <button
              type='submit'
              id='kt_sign_in_submit'
              className='btn btn-primary'
              onClick={handleResendOtp}
              disabled={resendTimer > 0 || resendLoading}
            >
              {resendLoading
                ? 'Resending...'
                : resendTimer > 0
                ? `Resend OTP in ${resendTimer}s`
                : 'Resend OTP'}
            </button>
          </div>
          <ToastContainer />
        </div>
      </form>
    );
  }
  return (
    <>
      <form id='kt_modal_add_coupon_form' className='form' onSubmit={handleSubmit} noValidate>
        <div
          className='d-flex flex-column me-n7 pe-7'
          id='kt_modal_add_coupon_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_coupon_header'
          data-kt-scroll-wrappers='#kt_modal_add_coupon_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Form group */}
          <div className='fv-row mb-8'>
            <label className='fw-bold fs-6 mb-2'>Phone Number</label>
            <PhoneInput
              defaultCountry='us'
              value={mobileNumber}
              onChange={(phone) => setMobileNumber(phone)}
              countries={countries}
              inputClassName={'form-control form-control-solid mb-3 mb-lg-0'}
              inputProps={{name: 'phoneNumber', autoComplete: 'off', disabled: loading}}
            />
            {message && (
              <div className='fv-plugins-message-container'>
                <span role='alert' style={{color: 'red'}}>
                  {message}
                </span>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Action */}
          <div className='d-grid mb-10'>
            <button type='submit' id='kt_send_otp_submit' className='btn btn-primary'>
              {!loading && <span className='indicator-label'>Send OTP</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          <ToastContainer />
        </div>
      </form>
    </>
  );
};

export default RestaurantMobileVerificationBody;
