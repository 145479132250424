import {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import {KTIcon} from '../../../../_metronic/helpers';
import {PageTitle} from '../../../../_metronic/layout/core';
import DataTableWrapper from '../../../DataTable';
import {API_ERROR_MESSAGE, TOAST_GENERAL} from '../../../Helpers';
import {CustomModal} from '../../../components/Modal';
import {CommonViewInHeader} from '../../../components/common/DataTableViews';
import {useSessionReducer} from '../../../hook/useSessionReducer';
import {useAuth} from '../../../modules/auth';
import reducerFunc from '../../Admin/User/action';

const initialState: any = {
  loading: true,
  data: [],
  totalData: -1,
  isModalOpen: false,
  modalType: 'AUCTION_TYPE',
  selectedRow: undefined,
  currentPage: 1,
  totalPages: 1,
  error: null,
  search: '',
  changeAttendanceId: null,
  shouldRefreshTable: true,
  pageSize: 10,
};

export const AuctionType = () => {
  const [state, dispatch] = useSessionReducer<any, any, string>(reducerFunc, initialState, 'USER');
  const [formLoading, setFormLoading] = useState(false);
  const {restaurantModule, currentUser} = useAuth();
  const [view, setView] = useState('');

  let columns = [
    {
      name: <CommonViewInHeader name='Auction Type' />,
      selector: (row, i) => row.type,
      sortable: true,
      // width: '500px',
      format: (row, i) => {
        return <span className='text-dark text-uppercase'>{row.type}</span>;
      },
    },
    {
      name: <CommonViewInHeader name='Action' />,
      selector: (row, i) => row.id,
      width: '200px',
      format: (row, i) => {
        return (
          <div className='d-flex align-items-center justify-content-center gap-2'>
            <span
              title='Edit auction type'
              className={`linkIcon `}
              onClick={() => {
                if (currentUser?.role === 'RESTAURANT') {
                  if (row?.restaurantId === null) {
                    toast.warning('Common types can not be deleted.');
                    return;
                  }
                }
                dispatch({type: 'SELECT_ROW', payload: row});
                dispatch({type: 'OPEN_MODAL', payload: 'AUCTION_TYPE'});
              }}
            >
              <KTIcon iconName='pencil' className='fs-1' />
            </span>
            <span
              title='Delete auction type'
              className={`linkIcon ${row?.deletedAt ? 'cursor-not-allowed' : ''}`}
              onClick={() => {
                if (currentUser?.role === 'RESTAURANT') {
                  if (row?.restaurantId === null) {
                    toast.warning('Common types can not be deleted.');
                    return;
                  }
                }
                dispatch({type: 'SELECT_ROW', payload: row});
                dispatch({type: 'OPEN_MODAL', payload: 'DELETE'});
              }}
            >
              <KTIcon iconName='trash' className='fs-1' />
            </span>
          </div>
        );
      },
      sortable: false,
    },
  ];

  const getCouponListing = async () => {
    dispatch({type: 'LOADING', payload: true});

    const result = await restaurantModule?.getAuctionTypeListing({
      pageNo: state.currentPage ? state.currentPage : 1,
      pageSize: state.pageSize,
      statusFilter:
        state.statusFilter === 'ACTIVE' ? true : state?.statusFilter === 'INACTIVE' ? false : '',
      searchKeyword: state.search,
      startDate: state.startDate,
      endDate: state.endDate,
    });

    if (result?.success) {
      dispatch({type: 'GET_DATA', payload: result?.data || []});
      dispatch({type: 'SET_TOTAL_PAGES', payload: result?.data?.count || 1});
    } else {
      dispatch({type: 'GET_DATA', payload: []});
      dispatch({type: 'SET_TOTAL_PAGES', payload: 1});

      if (result?.status !== 600) {
        toast.error(result?.message || API_ERROR_MESSAGE, {
          toastId: TOAST_GENERAL,
        });
      }
    }
  };

  const createCoupon = async (userData) => {
    setFormLoading(true);

    const result = await restaurantModule?.createAuctionType(userData);
    if (result?.success) {
      toast.success(`Auction Type created successfully!!`, {
        toastId: TOAST_GENERAL,
      });
      getCouponListing();
      return true;
    } else {
      toast.error(result?.message || `Problem occurred, While creating Admin`, {
        toastId: TOAST_GENERAL,
      });
      return false;
    }
  };

  const deleteCoupon = async (couponIds) => {
    dispatch({type: 'CLOSE_MODAL'});
    const result = await restaurantModule?.deleteAuctionType(couponIds);
    if (result?.success) {
      toast.success(`Auction Type Deleted successfully`);
      getCouponListing();
    } else {
      toast.error(result?.message || API_ERROR_MESSAGE, {
        toastId: TOAST_GENERAL,
      });
    }
  };

  useEffect(() => {
    getCouponListing();
  }, [state?.pageNo, state?.pageSize, state.search]);

  const deleteItem = function (rows) {
    dispatch({type: 'SELECT_ROW', payload: rows});
    dispatch({type: 'OPEN_MODAL', payload: 'DELETE'});
  };

  const subHeader = (
    <button
      type='button'
      className='btn btn-primary mt-1'
      style={{
        minWidth: '200px',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      }}
      onClick={() => {
        dispatch({type: 'OPEN_MODAL', payload: 'AUCTION_TYPE'});
      }}
    >
      <KTIcon iconName='plus' className='fs-2' />
      Add Auction Type{' '}
    </button>
  );

  let filters: any = {
    label: 'Status',
    options: ['Active', 'Inactive'].map((t) => ({
      value: t.toUpperCase(),
      label: t,
    })),
  };

  return (
    <>
      <PageTitle>Auctions Type</PageTitle>
      <DataTableWrapper
        totalData={state.totalData}
        subHeader={subHeader}
        selectableRows={true}
        loading={state.loading}
        columns={columns}
        data={state.data}
        handleSelected={deleteItem}
        currentPage={state.currentPage}
        totalPages={state.totalPages}
        pageSize={state.pageSize}
        handlePageChange={(page) => dispatch({type: 'PAGE_CHANGE', payload: page})}
        handlePageSizeChange={(pageSize) => dispatch({type: 'SET_PAGE_SIZE', payload: +pageSize})}
        handleSearch={(searchVal) => {
          dispatch({type: 'SEARCH_CHANGE', payload: searchVal});
        }}
        searchValue={state.search}
        isFilterApplied={Boolean(state.search || state.statusFilter)}
      />
      {state.isModalOpen && (
        <CustomModal
          data={state.selectedRow}
          handleCancelCallback={() => dispatch({type: 'CLOSE_MODAL'})}
          handleSaveCallback={async (value) => {
            if (state.modalType === 'DELETE') {
              const ids = Array.isArray(state.selectedRow)
                ? state.selectedRow.map((row) => row.id)
                : [state.selectedRow.id];
              deleteCoupon({ids: ids});
            } else if (state.selectedRow) {
              // result = await updateTAuctions(state.selectedRow.id, value);
            } else {
              const result = await createCoupon(value);
              if (result) dispatch({type: 'CLOSE_MODAL'});
              else return false;
            }
          }}
          title=''
          type={state.modalType}
        />
      )}
    </>
  );
};
